// SCSS 变量
$icon-font-path: "../fonts/bootstrap/";
$fa-font-path: "../fonts/font-awesome/";
$nav-width:                 220px;
$nav-back-color:            #373d41;
$green-dark:                #19aa8d;
$border-solid:              1px solid #ddd;
$border-dashed:             1px dashed #ddd;
$body-bg:                   #f0f2f5;
$form-group-margin-bottom:  20px;
$input-border:              #d9d9d9;
$link-color:                #1890ff;
$input-color:               rgba(0, 0, 0, 0.65);
$breadcrumb-bg:             transparent;
$blue:                      #1890ff;
$pagination-disabled-color: rgba(0, 0, 0, 0.25);

// btn
$btn-primary-bg: 			      #1890ff;
$btn-primary-border: 		    #1890ff;
$label-primary-bg: 			    #1890ff;
// form-control
$border-color-default:      #edf0f2;
// $border-radius-base:        2px;
$color-primary: 		        #2db7f5;
$navbar-width:              240px;
$transition-time:           .2s;

// tab
$nav-tabs-active-link-hover-bg: #fff;
$table-border-color:            #e8e8e8;

// page
$pagination-border:          #d9d9d9;
$pagination-hover-bg:        #fff;   


// Mixins
@mixin max-screen($res){
  @media only screen and ( max-width: $res )
  {
    @content;
  }
}
@mixin min-width($res){
  @media ( min-width: $res )
  {
    @content;
  }
}
@mixin opacity($opacity) {
    opacity: $opacity;
    filter: alpha(opacity=$opacity * 100);
}
@mixin loop-size-column($columns) {
  @for $i from 0 through $columns {
    .size-#{$i} {
    	width: percentage(($i / $columns)) !important;
    }
  }
}
@mixin loop-size-category($columns) {
  @for $i from 0 through $columns {
    .category-tree-#{$i+1} {
    	padding-left: 30px + 50*$i !important;
    }
  }
}