.color_pick {
    background: url("../images/down.png") no-repeat scroll 66px center #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    height: 32px;
    line-height: normal;
    overflow: hidden;
    padding: 2px 13px 2px 3px;
    position: relative;
    vertical-align: middle;
}

.color_pick:hover {
    background-color: #FFFBDE;
}

.color_pick.color_current {
    background-color: #FFFBDE;
    border-color: #aaa #aaa #555;
}

.color_pick em {
    background: url("../images/transparent.png") repeat scroll 0 0 transparent;
    display: inline-block;
    height: 26px;
    width: 60px;
}

.colorPicker {
    width: 189px;
    height: 170px;
    z-index: 200;
    overflow: hidden;
}

.wind_color_general,
.wind_color_senior {
    background: #fff;
    width: 189px;
    height: 170px;
    position: absolute;
    -moz-box-shadow: 0 1px 1px #F2F2F2;
    -webkit-box-shadow: 0 1px 1px #F2F2F2;
    box-shadow: 0 1px 1px #F2F2F2
}

.wind_color_general .color_general {
    height: 16px;
    margin: 11px 7px 9px 11px
}

.wind_color_general .color_list {
    height: 70px;
    margin: 0 7px 0 11px
}

.wind_color_general .color_standard {
    height: 16px;
    margin: 13px 7px 8px 11px
}

.wind_color_general strong {
    font-size: 0;
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 3px;
    float: left;
    cursor: pointer
}

.wind_color_general strong.selector span {
    font-size: 0;
    display: block;
    width: 12px;
    height: 12px;
    margin: 1px;
    border: 1px solid #FFF
}

.wind_color_general .set_general {
    text-align: center;
}

.wind_color_general .set_general .J_set_general {
    color: #105FBB;
    font-size: 12px;
    display: inline-block;
    text-decoration: none;
    line-height: 16px;
    background: url(images/color_picker.png) no-repeat -18px -172px;
    padding-left: 18px
}

.wind_color_general .set_general .no_color {
    display: inline-block;
    color: #266AAE;
}

.wind_color_general .set_general .no_color:hover {
    text-decoration: underline;
}