// tab 样式
.nav-tabs img {
	width: 16px;
	height: 11px;
}
.nav-tabs > li > a {
    color: #666;
    border-radius: 2px 2px 0 0;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    font-weight: bold;
    color: #333;
}
.language-tabs {
    a {
        font-size: 12px;
    }
}

// .nav-tabs {
//     border-color: #ddd
// }
// .nav-tabs {
//     li {
//         margin-left: -1px;
//         border-top: 1px solid #ddd;
//         border-left: 1px solid #ddd;
//         border-right: 1px solid #ddd;
//         z-index: 1;
//         a {
//             color: #666;
//             border-left: 0px;
//             border-right: 0px;
//             margin-right: 0px;
//             padding: 10px 16px;
//             background: #FBFAF8;
//             border-bottom: 0px;
//             border-radius: 0;
//             &:focus {
//                 color: #666;
//                 border-left: 0px;
//                 border-right: 0px;
//                 margin-right: 0px;
//                 padding: 10px 16px;
//                 background: #FBFAF8;
//                 border-bottom: 0px;
//             }
//             &:hover {
//                 background-color: #FFF;
//                 color: #2086ee;
//             }
//         }
//         &.active {
//             border-top: 0px;
//             border-left: 1px solid #ddd;
//             border-right: 1px solid #ddd;
//             z-index: 3;
//             a {
//                 border-top: 2px solid #2086ee;
//                 border-left: 0px;
//                 border-right: 0px;
//                 border-bottom: 1px solid #FFF;
//                 color: $blue;
//                 &:hover, &:focus {
//                     border-top: 2px solid #2086ee;
//                     border-left: 0px;
//                     border-right: 0px;
//                     border-bottom: 1px solid #FFF;
//                     color: $blue;
//                 }
//             }
//         }
//     }
// }