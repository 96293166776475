// 变量
@import "variables";

@import "mixins";

// 第三方插件
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/sweetalert/dist/sweetalert";

// A pure CSS library to beautify checkbox and radio buttons @https://github.com/lokesh-coder/pretty-checkbox
@import "node_modules/pretty-checkbox/src/pretty-checkbox.scss";

@import "component";
@import "menu";
@import "color.pick";
@import "tab";
@import "iview";
@import "detection";

// 后台管理样式
.signin-heading {
	margin-top: 120px;
    padding-bottom: 20px;
}
.form-group-line {
    border-top: 1px solid #ddd;
}
.overflow-auto-120 {
    overflow: auto;
    height: 120px;
}
.laodding {
    &:hover {
        background: none !important;
    }
}
.align-center {
    text-align: center;
}
#default-alert {
    border: none;
    background: none;
    color: #999;
}
#signin {
    width: 380px;
    margin: 0 auto;
    padding: 40px 30px;
    border: 1px solid #eee;
}
.container-fluid {
    padding-left: 0;
    padding-right: 0;
}
.toolbar {
    white-space: nowrap;
}
.border-solid {
    border-bottom: 1px solid #e6e6e6;
}
.border-dashed {
    border-bottom: 1px dashed #e6e6e6;
}
/* New admin panel ui style */
#app-side {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #001529;
    transition: all .12s ease;
    width: $nav-width;
    .side-header {
        height: 60px;
        line-height: 60px;
        padding-left: 25px;
        background: #002140;
        transition: all .3s;
        overflow: hidden;
    }
}
#logo {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    span {
        color: #fff;
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        margin-left: 3px;
        font-weight: 600;
    }
    img {
        display: inline-block;
        vertical-align: middle;
        height: 34px;
    }
}
#app-main {
    width: auto;
    position: absolute;
    top: 0px;
    left: $nav-width;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    transition: all .2s;
    .main-header {
        height: 60px;
        line-height: 60px;
        padding-right: 15px;
        background: #fff;
        position: relative;
        .topbar-right {
            .dropdown {
                #lang-select {
                    background: none;
                    border: none;
                    outline: none;
                }
                .dropdown-menu {
                    top: 95%;
                    min-width: auto;
                }
            }
        }
    }
}
.trigger-menu {
    font-size: 20px !important;
    line-height: 60px;
    cursor: pointer;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    padding: 0 24px;
    &:hover {
        background: #e6f7ff;
    }
}
.topbar-right {
    float: right;
    height: 100%;
}
.action-aside {
    display: inline-block;
    height: 100%;
    vertical-align: top;
    padding: 0 4px;
    color: #333;
    text-decoration: none;
    &:hover {
        background: #e6f7ff;
        text-decoration: none;
    }
}
.action-aside-notice {
    margin-right: 10px;
}
.topbar-notice {
    font-size: 18px;
    padding: 4px;
}
.view-badge {
    position: relative;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
}
.view-badge-count {
    position: absolute;
    transform: translateX(50%);
    top: -10px;
    right: 0;
    height: 20px;
    border-radius: 10px;
    min-width: 20px;
    background: #ed3f14;
    border: 1px solid transparent;
    color: #fff;
    line-height: 18px;
    text-align: center;
    padding: 0 6px;
    font-size: 12px;
    white-space: nowrap;
    transform-origin: -10% center;
    z-index: 10;
    box-shadow: 0 0 0 1px #fff;
}
.message-badge-count {
    padding: 0 8px;
}
.main-content {
    position: absolute;
    width: auto;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0px;
    overflow: hidden;
    border-top: 1px solid #eee;
    transition: all 0.2s ease;
    .content-nav {
        width: 160px;
        float: left;
        background-color: #fff;
        position: absolute;
        top: 0px;
        bottom: 0px;
        z-index: 2;
        overflow: hidden;
        display: none;
        transition: all 0.2s ease;
    }
    .content-body {
        position: absolute;
        width: auto;
        left: 0;
        top: 0px;
        bottom: 0px;
        right: 0px;
        overflow: hidden;
        transition: all 0.2s ease;  
    }
    &.shownav {
        .content-nav {
            display: block;
            border-right: 1px solid #e8e8e8;
        }
        .content-body {
            left: 160px;
        }
    }
}
.content-nav-title {
    height: 54px;
    line-height: 54px;
    background: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 20px;
    font-weight: bold;
    border-bottom: 1px solid $table-border-color;
}
.content-nav-list {
    li {
        a {
            display: block;
            height: 42px;
            line-height: 42px;
            display: block;
            color: #333;
            padding-left: 40px;
            text-decoration: none;
            &:hover {
                background: #F4F6F8;
            }
        }
        &.active a {
            background: #f0f2f5;
        }
    }
}
// .list-title {
//     color: rgba(0, 0, 0, 0.65);
//     &:hover {
//         color: #1890ff;
//     }
// }
form .tab-content {
    margin-top: 20px;
}
.foot-wrap .form-control {
    display: inline-block;
}
#errors-div {
    margin-bottom: 20px;
}
.pretty-box {
    padding-top: 7px;
}
// 时间选择
.form-datetime {
    position: relative;
}
.date-picker-icon {
    color: rgba(0,0,0,.43);
    position: absolute;
    width: 14px;
    height: 14px;
    right: 8px;
    top: 50%;
    margin-top: -7px;
    line-height: 14px;
    font-size: 14px;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.display-none {
    display: none;
}
/* New style end */

.navbar-logo {
    color: #fff;
    font-size: 30px;
    width: 50px;
    line-height: 48px;
    display: block;
    text-align: center;
    position: relative;
    border-right: 1px solid #2a2f32;
    &:hover {
        background-color: #2a2f32;
        color: #fff;
    }
    .iconfont {
        font-size: 24px;
    }
    img {
        width: 28px;
        height: auto;
    }
}

.navbar-inverse {
    background-color: #373d41;
    border-color: #373d41;
    .navbar-brand {
        color: #fff;
        margin-left: 0;
        font-size: 14px;
        width: 129px;
        text-align: center;
        letter-spacing: 0.04em;
    }
}

@include min-width(480px) {
    .navbar>.container {
        .navbar-brand {
            margin-left: 0;
        }
    }
    .navbar>.container-fluid {
        .navbar-brand {
            margin-left: 0;
        }
    }
}

.navbar-inverse {
    .navbar-brand {
        &:focus,&:hover {
            background-color: #2a2f32;
        }
    }
}


.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
    margin-right: 0;
    margin-left: 0;
}
.navbar-head {
    border-radius: 0;
    border: none;
}

.topbar-head {
    border-right: 1px solid #2a2f32;
}

.view-sidebar {
    width: 180px;
    display: block;
    position: fixed;
    top: 50px;
    bottom: 0px;
    background-color: #293038;
    z-index: 102;
    overflow-x: hidden;
}

.view-product {
    width: auto;
    position: absolute;
    top: 0px;
    left: 180px;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    background: #fff;
}

.navbar-inverse {
    .navbar-nav > li {
        > a {
            color: #fff;
            font-size: 13px;
            padding: 15px 25px;
            border-right: 1px solid #2a2f32;
            &.active, &:focus, &:hover {
                color: #fff;
                background-color: #2a2f32;
            }
        }
    }
}

.sidebar-toggle {
    height: 30px;
    width: 100%;
    background: #4A5064;
    color: #aeb9c2;
    text-align: center;
    line-height: 30px !important;
    font-size: 12px;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    -webkit-user-select: none;
    -moz-user-select: none;
}


.sidebar-nav-list {
    li {
        position: relative;
        a {
            display: block;
            width: 180px;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            color: #fff;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            &:hover {
                background: #4a5064;
            }
        }
        &.active {
            a {
                background: #00C1DE;
            }
        }
    }
}

.sidebar-icon {
    width: 50px;
    text-align: center;
}
.sidebar-text {
    width: 130px;
}
.sidebar-tooltip {
    position: absolute;
    z-index: 1030;
    display: block;
    padding: 0 5px;
    font-size: 12px;
    line-height: 1.4;
    opacity: 0.9;
    filter: alpha(opacity=0);
    visibility: visible;
    margin-left: 3px;
}
// .tooltip-arrow {
//     top: 50%;
//     left: 0;
//     margin-top: -5px;
//     border-right-color: #425160;
//     border-width: 5px 5px 5px 0;
// }
// .tooltip-inner {
//     max-width: 200px;
//     padding: 12px 8px;
//     color: #ffffff;
//     text-align: center;
//     text-decoration: none;
//     border-radius: 0 0;
//     background-color: #425160;
// }
.panel  {
    .panel-heading {
        border-bottom: 1px solid #eee;
        background-color: #fff;
    }
}
.complain-panel {
    .panel-title {
        font-size: 25px;
        color: #333;
        text-align: left;
        line-height: 135%;
        margin-bottom: 12px;
        font-weight: normal;
    }
    .meta {
        span {
            padding-right: 6px;
            color: #999;
        }
    }
}

.trace-list {
    .panel-title {
        font-size: 14px;
        color: #333;
        text-align: left;
        line-height: 135%;
        font-weight: normal;
    }
}
.avatar-title {
    width: 80px;
    text-align: center;
}
.trace-item {
    zoom: 1;
    overflow: hidden;
    .info {
        margin-left: 110px;
    }
    .label.role {
        padding: .1em .2em .1em;
        position: inherit;
        color: #949090;
        border: 1px solid #e0e0e0;
        font-weight: normal;
        display: inline-block;
        margin: 8px 0;
        background: transparent;
    }
}

.avatar-title {
    .name {
        display: inline-block;
        width: 55px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        background: #f2f2f2;
    }
}

.trace-head {
    margin-bottom: 8px;
    line-height: 30px;
    border-bottom: 1px dashed #ddd;
    span {
        color: #b0b0b0;
    }
    .username {
        color: #05a1a2;
        font-size: 14px;
    }
}
.topbar-dropdown-memu {
    position: absolute;
    padding: 0;
    top: 100%;
    right: 0;
    left: auto;
    float: left;
    list-style: none;
    background-color: #FFF;
    background-clip: padding-box;
    z-index: 101;
    font-size: 12px;
    min-width: 100%;
    margin: 0;
    border: none;
    display: none;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    -webkit-transition: opacity 0.15s, visibility 0s 0.15s;
    transition: opacity 0.15s, visibility 0s 0.15s;
}
.topbar-user {
    overflow: hidden;
    margin: 8px 0;
    width: 190px;
    padding: 8px 15px;
    a {
        width: 80px;
        height: 80px;
        float: left;
        text-align: center;
        color: #333;
        -webkit-transition: background 0.15s;
        transition: background 0.15s;
        border-radius: 2px;
        text-decoration: none;
        i {
            font-size: 20px;
            margin: 8px auto 4px;
            width: 40px;
            height: 40px;
            display: block;
            line-height: 40px;
            color: #788D9B;
        }
        &:hover {
            background: #f5f5f5;
        }
    }
}
.user-logout {
    a {
        height: 50px;
        line-height: 50px;
        display: block;
        -webkit-transition: all 0.15s;
        transition: all 0.15s;
        text-align: center;
        color: #333;
        border-top: 1px solid #f5f5f5; 
        text-decoration: none;
        &:hover {
            background: #f5f5f5;
        }
    }
}
.btn-notice {
    i {
        font-size: 18px;
        vertical-align: middle;
        margin-right: 4px;
    }

}
ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.text-explode {
    color: #e8e8e8;
    margin: 0;
    padding: 0 3px;
}
.button {
    background: #5cb85c none repeat scroll 0 0;
    border: medium none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    letter-spacing: 0.1em;
    overflow: visible;
    padding: 6px 10px;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    width: auto;
    &:hover {
        background: #e33b3d none repeat scroll 0 0;
    }
}
.label-width {
    width: 150px;
    img {
        width: 16px;
        height: 12px;
    }
}
.autosize {
    width: auto !important;    
}
.len-full {
    width: 100% !important;
}
.length-half {
    width: 50% !important;
}
.form-group {
    label {
        font-weight: normal;
    }
    .form-tag-add {
        a {
            display: inline-block;
            i {
                font-weight: bold;
            }
        }
    }
}
$lengths: (len: 320px, len50: 50px, len80: 80px, len100: 100px, len110: 110px, len150: 150px, len200: 200px, len500: 500px);
@each $lens, $size in $lengths {
    .#{$lens} {
        width: $size !important;
    }
}
$fontsizes: (font-10: 10px, font-12: 12px, font-13: 13px, font-14: 14px, font-15: 15px, font-16: 16px, font-18: 18px, font-20: 20px, font-50: 50px, font-100: 100px);
@each $fonts, $px in $fontsizes {
  .#{$fonts} {
      font-size: $px !important;
  }
}
$paddings: (pl-5: 5px, pl-7: 7px, pl-10: 10px, pl-15: 15px, pl-20: 20px, pl-119: 119px, pl-139: 139px, pl-149: 149px);
@each $pads, $px in $paddings {
    .#{$pads} {
        padding-left: $px !important;
    }
}
$paddrights: (pr-5: 5px, pr-7: 7px, pr-10: 10px, pr-15: 15px, pr-20: 20px);
@each $rights, $px in $paddrights {
    .#{$rights} {
        padding-right: $px !important;
    }
}
$paddtops: (pt-3: 3px, pt-5: 5px, pt-7: 7px, pt-10: 10px, pt-15: 15px, pt-20: 20px);
@each $padts, $px in $paddtops {
    .#{$padts} {
        padding-top: $px !important;
    }
}
$paddbottoms: (pb-3: 3px, pb-5: 5px, pb-7: 7px, pb-10: 10px, pb-15: 15px, pb-20: 20px);
@each $padbs, $px in $paddbottoms {
    .#{$padbs} {
        padding-bottom: $px !important;
    }
}
$marginlefts: (ml-5: 5px, ml-7: 7px, ml-10: 10px, ml-15: 15px, ml-20: 20px);
@each $lefts, $px in $marginlefts {
    .#{$lefts} {
        margin-left: $px !important;
    }
}
$marginrights: (mr-5: 5px, mr-7: 7px, mr-10: 10px, mr-15: 15px, mr-20: 20px, mr-30: 30px, mr-40: 40px);
@each $rights, $px in $marginrights {
    .#{$rights} {
        margin-right: $px !important;
    }
}
$margintops: (mt-0: 0px, mt-3: 3px, mt-5: 5px, mt-7: 7px, mt-10: 10px, mt-15: 15px, mt-20: 20px, mt-30: 30px, mt-40: 40px, mt-50: 50px);
@each $mts, $px in $margintops {
    .#{$mts} {
        margin-top: $px !important;
    }
}
$marginbottoms: (mb-3: 3px, mb-5: 5px, mb-7: 7px, mb-10: 10px, mb-15: 15px, mb-20: 20px, mb-30: 30px, mb-40: 40px);
@each $mbs, $px in $marginbottoms {
    .#{$mbs} {
        margin-bottom: $px !important;
    }
}
#main-view {
    position: relative; 
    .main-top {
        height: 54px;
        border-bottom: 1px solid #e8e8e8;
        padding: 0 28px;
        overflow: hidden;
        background: #fff;
        // position: fixed;
        width: 100%;
        // top: 0;
        z-index: 101;
        .top-breadcrumb {
            padding-top: 16px;
            h5 {
                display: inline-block;
                text-indent: 8px;
                border-left: 2px solid #1890ff;
                margin-bottom: 0px;
                margin-right: 8px;
                vertical-align: middle;
                margin-top: 0; 
                font-size: 14px;
            }
        }
        .top-btn-group {
            padding: 10px 0px;
        }
    }
}
#content {
    margin: 16px 22px;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 3px rgba(52,63,75,.1);
    padding: 20px;
    border-radius: 2px;
}
.alert {
    padding: 10px 12px;
    line-height: 18px;
    margin-bottom: 6px;
}
.alert-danger {
    color: #ee2117;
    background-color: #FFF6F2;
    border-color: #F1ACAC;
    .alert-link {
        color: #b00;
        font-weight: normal
    }
}
.nodata-wrap {
    padding: 0;
    text-align: center;
    .notice-null {
        padding: 16px 16px;
        border-bottom: 1px solid #e8e8e8;
    }
}

.inline-block {
    display: inline-block;
    vertical-align: middle;
}
.form-control {
    // display: inline;
}
.upload-sm {
    a {
        text-decoration: none;
        transition: all 0.3s ease 0s;
        &:hover, &:active, &:visited, &:focus {
            text-decoration: none;
            color: $blue;
            background: #fff;
        }
    }
}
.attachments {
    .thumbnail {
        float: left;
        text-align: center;
        margin-right: 15px;
        margin-bottom: 10px;
        margin-top:10px;
        cursor:pointer;
        position: relative;
        .thumbnail-img {
            display: block;
            img {
                width: 80px;
                height: 80px;
            }
        }
        .thumbnail-remove {
            line-height: 26px;
            text-align: center;
        }
    }
    .ads-thumbnail {
        padding: 15px 0;
        cursor:pointer;
        border-bottom: 1px dashed #ddd;
        input {
            display: inline-block;
            margin-left: 5%;
        }
        .thumbnail-img {
            display: inline-block;
            padding: 5px;
            border: 1px solid #ddd;
            border-radius: 4px;
            background-color: #f0f2f5;
            &:hover {
                border: 1px solid #1890ff;
            }
            img {
                width: 80px;
                height: 80px;
            }
        }
        .thumbnail-flag-remove {
            float: right;
            margin-top: 34px;
            i {
                color: #888;
                font-size: 30px;
            }
            &:hover i {
                color: #f90;
            }
        }
    }
}

.thumbnail-flag-remove {
    line-height: 26px;
    text-align: center;
}
.tab-content {
    a.thumbnail-edit {
        display: none;
        border-radius: 3px;
        color:#fff;
        position: absolute;
        top: 33px;
        left: 18px;
        padding: 4px 6px;
        background-color: rgba(5,5,5,.5);
        z-index: 999;
        -webkit-transition: -webkit-transform .3s ease;
        -moz-transition: -moz-transform .3s ease;
        -ms-transition: -ms-transform .3s ease;
        transition: all 0.3s ease 0s;
        &:focus,&:hover {
            color: #fff;
            text-decoration: none;
            background-color: rgba(5,5,5,.5);
        }
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: rgba(250,127,6,.85);
            .thumbnail-edit {
                display: block;
            }
        }
    }

}
.col-img-edit{
    padding: 8px 15px;
    text-align: right;
}
.img-circle {
    border-radius: 50%;
    &.user-img-list {
        height: 50px;
        width: 50px;
    }
}
.changeyellow {
    background-color: #f90;
    border-radius: 3px;
    color: #fff;
    padding: 5px 8px;
}
//提示框
.modal-header {
    .close {
        font-size: 28px;
        margin-top: -8px;
        font-weight: normal;
    }
}
.text-size-32 {
    font-size: 32px !important;
}
.blue {
    color: #2086ee !important;
    &:hover {
        color: #2086ee !important;
    }
}
.breakall {
    word-break: break-all;
    word-wrap: break-word;
    font-size: 16px;
    p {
        line-height: 24px;
        color: rgb(51, 51, 51);
    }
}
.text-muted {
    color: #999 !important;
}

.lang-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.ml-10,.ml10 {
    margin-left: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mb10, .mb-10 {
    margin-bottom: 10px;
}
.lang-hr {
    border-top: 1px dashed #ddd;
    padding-top: 20px;
}
.red {
    color: #ff0000;
}
.attachments {
    .thumbnail {
        &:hover {
            .thumbnail-edit {
                display: block;
            }
        }
    }
}
.fa-size-small {
    font-size: 12px;
    padding-right: 6px;
}
.changegreen {
    padding: 5px 8px;
    color: #fff;
    background-color: #5cb85c;
    border-radius: 3px;
}
.changegray {
    padding: 5px 8px;
    color: #fff;
    background-color: #b3b2b2;
    border-radius: 3px;
}

/*水印设置*/
.watermark_pos {
    border: 1px solid #E2E2E2;
    background: white;
    width: 152px;
    ul {
        margin-top: -1px;
        margin-left: -1px;  
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        li {
            border-left: 1px solid #E2E2E2;
            border-top: 1px solid #E2E2E2;
            float: left;
            width: 50px;
            height: 34px;
            text-align: center;
            a {
                color: #333;
                display: block;
                border: 1px solid white;
                height:32px; 
                &:hover {
                    background: #ddd;
                }
            }
            &.on {
                a {
                    background: #00b7ee;
                }
            }
        }
    }
}

.font-attribute-item {
  margin-right: 10px;
  label {
      margin-right: 5px;
  }
}
.label-checkbox {
  margin-right: 5px;
  vertical-align: middle;
}
.filepath {
    color: #666;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    margin-left: 10px;
    padding: 7px 12px;
    transition: all 0.3s ease 0s;
    &:focus, &:visited {
        text-decoration: none;
        color:#666;
    }
    &:hover {
        border: 1px solid #ccc;
        background-color: #fff;
        text-decoration: none;
        color:#000;
    }
}

a.laber-com {
  padding: 9px 16px;
  transition: all 0.3s ease 0s;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  border-radius: 0;
  i {
      font-size: 14px;
      padding-right: 6px;
  }
  &:hover {
      background: #fa7f06;
  }
}
.input-date {
    width: 200px;
    display: inline-block;
    background: #fff;
    border: 1px solid #d0d0d0;
    margin-right: 10px;
    .input-sm {
        width: 170px;
        display: inline-block;
        border: none;
        padding: 6px 12px;
        height: 34px;
        box-shadow: none;
        font-size: 14px;
    }
}
.changeblue {
    padding: 5px 8px;
    color: #fff;
    background-color: #2086ee;
    border-radius: 3px;
}
.changeyellow {
    padding: 5px 8px;
    color: #fff;
    background-color: #f90;
    border-radius: 3px;
}

.pagination-info {
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    padding: 0px 16px;
    color: #888;
}
.pagination {
    margin: 0px;
    vertical-align: middle;
    border-radius: 0px;
    > li {
        display: inline-block;
        margin-right: 8px;
        border-radius: 4px;
        a {
            height: 32px;
            line-height: 20px;
            color: #333;
            cursor: pointer;
            border-color: #d9d9d9;
            border-radius: 4px;
            &:hover {
                color: $blue;
                border-color: $blue;
            }
        }
        span {
            height: 32px;
            line-height: 20px;
            color: #999;
            cursor: pointer;
            border-color: #d9d9d9;
            border-radius: 4px;
            &:hover {
                background: none;
            }
        }
        &.active {
            a,span {
                background-color: $blue;
                border: 1px solid $blue;
                &:hover {
                    background-color: lighten($blue, 10%);
                    border: 1px solid lighten($blue, 10%);
                    cursor: pointer;
                }
            }
        }
    }
}

.order-search {
    padding: 10px;
    .alert-danger {
        background: none;
        border: none;
        padding: 0;
        line-height: 32px;
        margin: 0;
    }
}

.theme-list {
    overflow: hidden;
    li {
        float: left;
        margin: 0 2% 3% 2%;
        border: 1px solid #ddd;
        padding: 4px;
        border-radius: 3px;
        &:hover {
            border: 1px solid #5cb85c;
        }
        .theme-list-img {
            position: relative;
            overflow: hidden;
            img {
                max-width: 300px;
            }
        }
        .theme-list-item {
            height: 40px;
            line-height: 40px;
            span {
                font-weight: bold;
                padding-top: 4px;
            }
            .theme-list-check {
                padding-right: 5px;
                line-height: 40px;
                float: right;
                vertical-align: middle;
                i {
                    cursor: pointer;
                    font-size: 18px;
                }
            }
        }
    }
}
.icon-morendizhi {
    color: #f90;
}

.text-blue {
    color: #2086ee;
}

.top-btn-group a.on {
	background: #449d44;
}

.langs-list {
	padding: 3px 6px;
	li {
		margin: 7px 0;
		a {
			color: #333;
			span {
				padding-left: 6px;
			}
		}
		a:hover {
			color: #e87518;
			text-decoration: none;
		}
	}
}

.pass-hidden {
    border: none;
    position: absolute;
    background: none;
    right: 0;
}
.train-video {
    overflow: hidden;
    li {
        float: left;
        margin: 0 2% 3% 2%;
        border: 1px solid #ddd;
        padding: 4px;
        border-radius: 3px;
        transition: all 0.3s ease 0s;
        &:hover {
            border: 1px solid #1890ff;
        }
        .train-video-img {
            position: relative;
            z-index: 999;
            overflow: hidden;
            img {
                width: 240px;
                height: auto;
            }
        }
        h3 {
            a {
                color: #666;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                text-align: center;
                width: 240px;
                &:hover {
                    color: #1890ff;
                }
            }

        }
    }
}
.theme-install-list {
    overflow: hidden;
    li {
        float: left;
        margin: 0 2% 3% 2%;
        border: 1px solid #ddd;
        padding: 4px;
        border-radius: 3px;
        transition: all 0.3s ease 0s;
        &:hover {
            border: 1px solid #5cb85c;
        }
        .theme-list-img {
            position: relative;
            z-index: 999;
            height: 240px;
            overflow: hidden;
            img {
                width: 240px;
                height: auto;
            }
            .back-color {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                background: rgba(0,0,0,0);
                z-index: 999;
                transition: all 0.3s ease 0s;
            }
            a {
                transition: all 0.5s ease 0s;
                border-radius: 3px;
                opacity: 0;
                color: #fff;
                font-size: 16px;
                padding: 6px 12px;
                background: rgba(88,116,216,1);
                position: absolute;
                z-index: 9999;
                &:visited {
                    text-decoration: none;
                    color: #fff;
                }
                &:hover {
                    text-decoration: none;
                    color: #fff;
                    background-color: #f90; 
                }
            }
            .theme-preview {
                left: 20px;
                top: 90px;
            }
            .theme-download {
                right: 20px;
                top: 90px;
            }
            i {
                font-size: 20px;
                padding-right: 8px;
            }
            &:hover {
                a {
                    opacity: 1;
                }
                .back-color {
                    background: rgba(0,0,0,.8);
                }
            }
        }
        .theme-list-text {
            line-height: 40px;
            text-align: center;
            .text-blues {
                color: #5874d8;
            }
        }
    }
}
.loading {
    margin: 15px;
    text-align: center;
    display: none;
    img {
        max-width: 200px;
        height: auto;
    }
}
.text-yellow {
    color: #f90;
}

// 后台登录样式
.login-input-placeholder {
    @include placeholder(#fff);
}
#login-view {
    background-image: url(http://wm.cdn.cn86.cn/test/2018/03/10498a84223rzh1a.jpg);
    position: relative;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    .login-box {
            width: 400px;
            height: 400px;
            padding: 35px;
            color: #EEE;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -200px;
            margin-top: -300px;
        .logo-box {
            background: url(../images/login_logo.png) 50% 50% no-repeat;
            height: 130px;
        }
        .input-outer {
            height: 46px;
            position: relative;
            overflow: hidden;
            .text {
                position: relative;
                width: 100%;
                height: 46px;
                outline: none;
                display: block;
                text-indent: 50px;
                border: none;
                background: none;
                line-height: 46px;
                border-radius: 5px;
                z-index: 9;
                color: #fff;
                background: rgba(0, 150, 230, 0.3);
            }
            input:-webkit-autofill,
            input:-webkit-autofill:focus {
                box-shadow: 0 0 0 1000px rgba(0, 150, 230, 0.3) inset !important;
                -webkit-box-shadow: 0 0 0 1000px rgba(0, 150, 230, 0.3) inset !important;
                -webkit-text-fill-color: #fff;
                transition: background-color 500000s ease-in-out 0s;
            }
            .iconfont {
                position: absolute;
                left: 0;
                top: 0;
                height: 46px;
                width: 50px;
                z-index: 99;
                color: #fff;
                line-height: 46px;
                text-align: center;
                font-size: 24px;
            }
        }
        .btn-login {
            line-height: 20px;
            text-align: center;
            font-size: 14x;
            border-radius: 5px;
            background: #008df6;
            outline: none;
            color: #fff;
            &:hover {
                background: #0674c7;
                color: #fff;
            }
        }
        .alert-danger {
            color: #fff;
            background: none;
            border: none;
        }
    }
    .login-footer {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 25px;
        color: #fff;
    }
}
.statistic-top {
    overflow: hidden;
    .btn {
        &.on {
            color: #fff !important;
            background-color: #2b85e4 !important;
            border-color: #2b85e4 !important;
        }
    } 
}
.statistic-list {
    overflow: hidden;
    li {
        border: 1px solid #ddd;
        padding: 0;
        span {
            display: block;
            line-height: 40px;
            text-align: center;
            &.statistic-time {
                background: linear-gradient(#f7f7f7, #efefef, #efefef);
                border-bottom: 1px solid #ddd;
                font-weight: bold;
            }
        }
    }
}
.statistic-title {
    padding: 14px 0;
    border-bottom: 2px solid #e3e8ee;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
}
.statistic-ellipsis {
    display: block;
    width: 100%;
    overflow: hidden;
    cursor: default;
}
.padding-0-8 {
    padding: 0 6px;
}

.form-label-prompt > label > span[data-toggle="tooltip"]:after {
    font-family: FontAwesome;
    color: #1E91CF;
    content: "\f059";
    margin-left: 4px;
}
// 后台首页
.opacity-005 {
  @include opacity(0.05);  
}
.opacity-06 {
  @include opacity(0.6);  
}
.btn-link:hover {
    text-decoration: none;
}
.text-box {
    background: #fff;
    padding: 10px 15px 20px;
    box-shadow: 0 1px 3px rgba(52,63,75,.1);
}
.contents {
    margin: 16px 22px;
    position: relative;
    border-radius: 2px;
    .text-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
        .text-boder {
            border-bottom: 2px solid #1890ff;
            margin-bottom: -1px;
            padding-right: 25px;
            i {
                font-size: 18px;
            }
        }
        .text-more {
            color: #999;
            &:visited {
                text-decoration: none;
                color: #999;
            }
            &:hover {
                text-decoration: none;
                color: #fa8564;
            }
        }
    }
    .train-news {
        overflow: hidden;
        li {
            line-height: 28px;
            a {
                color: #333;
                transition: all .2s ease;
                i {
                    color: #999;
                    font-size: 12px;
                    padding-right: 5px;
                }
                &:hover {
                    text-decoration: none;
                    color: #1890ff;
                }
            }
            .train-time {
                float: right;
                color: #999;
            }
        }
    }
    #train-video {
        overflow: hidden;
        .train-video-top {
            position: relative;
            border-right: 1px dashed #d0d0d0;
            .train-video-img {
                height: 200px;
                width: auto;
                border-radius: 3px;
            }
            .train-top-content {
                position: absolute;
                left: 310px;
                top: 0;
                bottom: 0;
                right: 15px;
                color: rgba(0, 0, 0, 0.65);
                h2 {
                    margin-top: 0;
                    font-size: 19px;
                    color: #4b5e6f;
                    font-weight: bold;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    a {
                        color: #4b5e6f;
                        &:visited {
                            text-decoration: none;
                            color: #4b5e6f;
                        }
                        &:hover {
                            color: #1890ff;
                        }
                    }
                }
                .train-top-attribute {
                    span {
                        color: #fa8564;
                    }
                }
                .train-top-abstract {
                    line-height: 24px;
                }
            }
        }
    }
    &.odd-content {
        table tr {
            td {
                padding: 10px;
            }
            td:nth-child(odd) {
                background-color: #fcfcfc;
            }
        }
    }
    .keyword-content {
        li {
            line-height: 50px;
            border-bottom: 1px solid #eee;
            position: relative;
            i {
                color: #1890ff;
                margin-right: 5px;
            }
            span {
                position: absolute;
                right: 10px;
                top: 0;
                color: #1890ff;
            }
        }
    }
    .main-header {
        .top-box {
            height: 130px;
            overflow: hidden;
            position: relative;
            color: #fff;
            .icon-right {
                color: #fff;
                font-size: 130px;
                position: absolute;
                right: -22px;
                bottom: -45px;
            }
            .icon-text {
                line-height: 140px;
                font-size: 80px;
                position: absolute;
                left: 10%;
                top: 0;
            }
            .texts {
                position: absolute;
                left: 33%;
                top: 15px;
                span {
                    font-family: 'Impact';
                    font-size: 50px;
                }
            }
            .texts {
                padding-left: 40px;
            }
            &.pv {
                background: #0aa699;
            }
            &.product {
                background: #0090d9;
            }
            &.news {
                background: #a48ad4;
            }
            &.message {
                background: #fa8564;
            }
        }
        
    }
    .test-content {
        text-align: center;
        p {
            &.test-title {
                font-size: 18px;
                color: #849895;
                font-weight: bold;
            }
        }
        span {
            font-weight: bold;
            color: #f90;
        }
    }
    .test-link {
        text-align: center;
        display: block;
        padding: 15px 0;
        background-color: #a48ad4;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
        transition: all .2s ease;
        &:hover {
            background-color: #9e71f9;
        }
    }
}
@media (max-width: 992px) {
    .contents {
        #train-news {
            height: auto !important;
        }
        #train-video {
            height: auto !important;
            .train-video-top {
                border: none;
            }
        }
        .train-video-item {
            margin-top: 20px;
            border-top: 1px dashed #d0d0d0;
            padding-top: 10px;
        }
    }
}
.text-copyright {
    text-align: center;
    padding: 10px 10px 0;
}
.language-list {
    overflow: hidden;
    li {
        display: inline-block;
        margin: 0 10px;
        a {
            display: inline-block;
            vertical-align: top;
            img,
            svg {
                border: 1px solid #e6e6e6;
                width: 70px;
                height: 48px;
            }
            p {
                line-height: 34px;
                font-size: 12px;
                margin: 0;
            }
        }
        &.no {
            a {
                color: #bcbbbb;
            }
        }
    }
}
.opened {
    color: #1890ff;
    line-height: 30px;
    .alert-danger {
        border: none;
        background: none;
        color: #1890ff;
    }
}
.train-detail {
    .video-detail-title {
        text-align: center;
        font-size: 24px;
        color: #333;
        border-bottom: 1px solid #e6e6e6;
    }
    .attribute {
        font-weight: bold;
        border-bottom: 1px dashed #e6e6e6;
        span {
            font-weight: normal;
            color: #666;
        }
        a {
            font-weight: normal;
        }
    }
    #video-box {
        width: 500px;
        margin: 0 auto;
        .video-js {
            width: 500px !important;
            height: 360px !important;
        }
    }
    .content-title {
        padding: 15px;
        background: #fafafa;
        border-bottom: 1px solid #e8e8e8;
        font-size: 16px;
    }
    .content-text {
        padding: 10px;
        line-height: 24px;
        color: #666;
    }
    .page-next-prev {
        border-top: 1px dashed #e8e8e8;
        color: #888;
        .arrow {
            display: inline-block;
            padding: 3px 14px;
            color: #333;
            border: 1px solid #e8e8e8;
            border-radius: 3px;
            transition: all .2s ease;
            &:hover {
                color: #1890ff;
                border: 1px solid #1890ff;
                i {
                    color: #1890ff;
                }
            }
        }
        .page-link {
            color: #888;
            transition: all .2s ease;
            &:hover {
                text-decoration: none;
                color: #1890ff;
            }
        }
        .previous-page {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .next-page {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: right;
            a {
                i {
                    transform:rotate(180deg);
                }
            }
        }
    }
}
.entry-rule {
    padding: 10px;
    line-height: 24px;
    border: 1px solid #e5e5e5;
    background: #f9f9f9;
    color: #666;
    margin-bottom: 15px;
}
.table_form {
    width: 100%;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,td {
            padding: 6px 0;
        }
        span {
            display: none;
            &.form-item-required {
                color: #ff0000;
                margin-left: 2px;
                display: inline-table;
            }
        }
    }
}
.share-notice {
    color: #f90;
    padding-bottom: 20px;
    border-bottom: 1px dashed #d9d9d9;
}
