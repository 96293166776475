// link
a {
    text-decoration: none;
    &:active, &:hover,&:visited {
        text-decoration: none;
    } 
    transition: color .3s;
}

// font
.iconfont {
    display: inline-block;
    transition: all .2s ease;
}

// btn
.btn {
    padding: 8px 16px;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: none;
    &:active,
    &.focus {
      box-shadow: none;
    }
}
.btn, .btn:active, .btn:focus {
    outline: 0;
}
.btn.btn-default {
    color: #333;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    &:hover, &:focus, &:active,  {
        color: $blue;
        border: 1px solid $blue;
        background: #fff;
    }
}
.btn-xs {
    padding: 1px 5px;
    font-size: 11px;
    line-height: 1.2;
    border-radius: 3px;
}
.btn-sm {
    font-size: 12px;
    padding: 4px 12px;
    height: 24px;
    line-height: 14px;
}
.btn .iconfont {
    vertical-align: middle;
}
.btn > .iconfont + span {
    margin-left: 6px;
}
.btn-primary {
    @include button-variant-custom($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
// form
.form-control {
    padding: 4px 11px;
    height: 32px;
    box-shadow: none;
    &:hover, &:focus {
        border-color: #40a9ff;
    }
}
.control-label {
    color: rgba(0, 0, 0, 0.85);
}

// table
.table {
    background: #fff;
    margin-top: 8px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 4px 4px 0 0;
}
.table thead > tr > th, .table tbody > tr > td {
    padding: 16px 16px;
    word-break: break-all;
    span {
        white-space: nowrap;
    }
}
.table > thead > tr > th {
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    transition: background .3s ease;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
}
.table-hover {
    > tbody > .footer:hover {
      background-color: transparent;
    }
}
.table {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          vertical-align: middle;
        }
      }
    }
  }

// item required
.form-item-required .control-label:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 12px;
    color: #ed3f14;
}

// breadcrumb
.breadcrumb {
    padding: 0;
}