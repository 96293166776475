@font-face {
  font-family: "Ionicons";
  src: url(../fonts/iview/iconionicons.eot?2c2ae068be3b089e0a5b59abb1831550);
  src: url(../fonts/iview/iconionicons.eot?2c2ae068be3b089e0a5b59abb1831550#iefix) format("embedded-opentype"), url(../fonts/iview/iconionicons.ttf?24712f6c47821394fba7942fbb52c3b2) format("truetype"), url(../fonts/iview/iconionicons.woff?05acfdb568b3df49ad31355b19495d4a) format("woff"), url(../fonts/iview/iconionicons.svg?621bd386841f74e0053cb8e67f8a0604#Ionicons) format("svg");
  font-weight: normal;
  font-style: normal;
}
.iview-icon {
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.iview-icon-load-c {
    color: #39f;
}
.iview-icon-load-c:before {
  content: "\F29C";
}
.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
.demo-spin-col {
    height: 100px;
    position: relative;
    border: 1px solid #eee;
}