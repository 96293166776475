#menu {
    position: absolute;
    width: 100%;
    top: 60px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color:rgba(129,129,129,0.6);
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color:rgba(129,129,129,0.5);
    }
}
.pony-menu {
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    z-index: 1050;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    line-height: 0;
    transition: background .3s, width .2s;
    zoom: 1;
}
.pony-menu-submenu {
    color: rgba(255, 255, 255, 0.65);
    background: #001529;
    .submenu-panel {
        height: 0;
        transition: all 0.12s ease;
        background: #000c17;
        overflow: hidden;
    }
    ul {
        
    }
    &.active {
        color: #fff;
        .pony-menu-submenu-arrow {
            transform: rotate(90deg); 
        }
        ul {
            display: block;
        }
    }
}
.pony-menu-item, .pony-menu-submenu-title {
    padding: 0 16px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    padding-left: 24px;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        color: #fff;
    }
}
.pony-menu-item {
    padding-left: 48px;
    > a {
        display: block;
        color: rgba(255, 255, 255, 0.65);
        &:focus {
            text-decoration: none;
        }
        &:hover {
            color: #fff;
        }
    }
    &.active {
        background-color: #1890ff;
       > span {
           color: #fff;
       }
    }
}
.pony-menu-submenu-arrow {
    transition: all .2s ease-in-out;
    float: right;
    position: relative;
    top: 0;
}
.pony-menu-item .iconfont + span, .pony-menu-submenu-title .iconfont + span {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
}
.submenu-panel {
    h3 {
        display: none;
        border-bottom: 1px solid #384c5f;
        line-height: 40px;
        margin: 0;
        font-size: 15px;
        padding-left: 20px;
    }
}

/*  collapsed */

.collapsed {
    #app-side {
        width: 80px;
    }
    #app-main {
        left: 80px;
    }
    .pony-menu-submenu {
        &.active {
            background: #193241;
        }
        .submenu-panel {
            top: 0;
            bottom: 0;
            height: auto !important;
            left: 100%;
            position: absolute;
            min-width: 130px;
            z-index: 1050;
            display: none;
            background: rgba(0, 21, 41, 0.9);
        }
        &:hover .submenu-panel {
            display: block;
        }
        &:hover {
            background: #193241;
        }
        .pony-menu-item {
            padding-left: 20px;
        }
    }
    .pony-menu-submenu-title {
        .iconfont + span {
            max-width: 0;
            display: inline-block;
            opacity: 0; 
        }
    }
    .pony-menu-submenu-arrow {
        display: none;
    }  
    #menu {
        overflow: inherit;
    }
    .iconfont {
        font-size: 20px;
    }
    .submenu-panel {
        h3 {
            display: block;
        }
    }
}