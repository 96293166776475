.detection {
	.conts {
		.title {
			font-size: 16px;
			line-height: 40px;
			background: #fafafa;
			.txt-color {
				color: #ff6625;
			}
		}
		.list {
			li {
				line-height: 36px;
				border-bottom: 1px dashed #e8e8e8;
				.success {
					color: #009839;
				}
				.error,
				.text {
					color: #ff6625;
				}
				.text {
					font-size: 12px;
				}
			}
		}
	}
	.result {
		font-size: 16px;
		span {
			color: #ff6625;
			font-weight: bold;
		}
	}
}

.detection-exponent {
	text-align: center;
	padding-top: 40px;
	background: #f0f2f5;
	padding-bottom: 45px;
	.pie {
		float: none;
		display: inline-block;
		vertical-align: middle;
		width: 170px;
		height: 170px;
		line-height: 170px;
	}
	.detection-check {
		display: inline-block;
		vertical-align: middle;
		p {
			font-size: 16px;
		}
		span {
			color: #9e71f9;
			font-weight: bold;
		}
		.detection-check-title {
			font-size: 40px;
			font-weight: bold;
			color: #9e71f9;
			margin-bottom: 5px;
		}
		.btn-primary {
			background-color: #9e71f9;
			border-color: #9e71f9;
			&:hover {
				background-color: #f90;
				border-color: #f90;
			}
		}
	}
}

.progress-content {
	position: relative;
	.detection-progress {
		overflow: hidden;
		height: 20px;
		margin-bottom: 20px;
		background-color: #f5f5f5;
		border-radius: 4px;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
		.progress-bar-info {
			background-color: #a48ad4;
			background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    		background-size: 40px 40px;
		}
		.progress-bar-1 {
			float: left;
		    width: 0%;
		    height: 100%;
		    font-size: 12px;
		    line-height: 20px;
		    color: #fff;
		    text-align: center;
		    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
		}
	}
	.progress-icons {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		.i-icon {
			width: 25%;
			text-align: center;
			position: relative;
			height: 26px;
			font-size: 12px;
			color: #333;
			line-height: 20px;
			span {
				width: 26px;
				height: 26px;
				border-radius: 50%;
				background: #fff;
				display: inline-block;
				background: #ecebeb;
				position: absolute;
				z-index: 99;
				top: -4px;
				right: -15px;
			}
			&.active span{
				background-color: #a48ad4;
				background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
				background-size: 40px 40px;
			}
		}
	}
}
